/**
 * Hardcoded feature list
 */
export const features = [
  {
    name: 'Teams',
    items: [
      {
        name: 'Roles',
        description:
          "Create, edit and manage user roles with permissions that are assigned according to a user's position in your company."
      },
      {
        name: 'Groups',
        description:
          'Create groups for your users with different teams or lines of business. You can filter by groups in the pipeline, clients, proposals and reports.'
      },
      {
        name: 'Permissions',
        description:
          'Easily assign and create preset permissions for a user based on their role in the company.'
      }
    ]
  },
  {
    name: 'Interactive Proposal',
    items: [
      {
        name: 'Financing',
        description:
          'Allow customers to apply for financing with a click of a button. This also includes Bolster Capital where you can apply for small business loans.'
      },
      {
        name: 'Profits',
        description:
          'Always know the profit you will make by setting a default. Bolster will warn you if you are falling under your set profit percentage.'
      },
      {
        name: 'Upgrades',
        description:
          'Allow your client’s to shop and choose upgrades. Proposals price will instantly update.'
      },
      {
        name: 'Selection pricing',
        description:
          'Create a shopping cart experience in proposals with selection pricing. Add styling or product variations that automatically update the pricing. '
      },
      {
        name: 'Images/Video',
        description: 'Explain and market your proposal items with videos and images.'
      },
      {
        name: 'Automated quote follow ups',
        description:
          'Instantly get notified when a client has viewed your proposal, request a change order or accept a job.'
      },
      {
        name: 'Presentation templates',
        description:
          'Create & manage proposal presentations, email templates. Brand your proposal with your company logo, credentials, featured project image and more.'
      }
    ]
  },
  {
    name: 'Job Tracking',
    items: [
      {
        name: 'Scheduling',
        description:
          'Schedule a start and end date for construction stages, vendors and trade groups.'
      },
      {
        name: 'Material list',
        description:
          'Auto generated material list that includes dimension quantity, cost per unit and total cost. Easily export material list as a csv.'
      },
      {
        name: 'Budget',
        description:
          'Manage your current cashflow, future cash flow, total profit, material cost percentage, and labor cost percentage. Budget spreadsheet view and export.'
      },
      {
        name: 'Vendor management',
        description:
          'Keep track and categorize your vendors. Assign vendors to project assemblies and items.'
      }
    ]
  },
  {
    name: 'Invoice Payments',
    items: [
      {
        name: 'Invoicing',
        description:
          'Get paid faster and send virtual invoices to your clients. Allow clients  to instantly pay. Create and send multiple invoices for each job.'
      },
      {
        name: 'Bank transfer',
        description:
          'Auto generated material list that includes dimension quantity, cost per unit and total cost. Easily export material list as a csv.'
      },
      {
        name: 'Credit card processing',
        description:
          'Manage your current cashflow, future cash flow, total profit, material cost percentage, and labor cost percentage. Budget spreadsheet view and export.'
      },
      {
        name: 'Payout management',
        description:
          'Turn on or off automatic daily payouts. Manually create payouts from your Bolster wallet.'
      },
      {
        name: 'Automated invoice notifications',
        description:
          'Receive notifications when your invoices are paid and the money has reached your account.'
      }
    ]
  },
  {
    name: 'Customer Management',
    items: [
      {
        name: 'CRM',
        description:
          'Grow your business with better lead management. Easily manage follow-up and share files. Convert leads into projects with a click.'
      },
      {
        name: 'Lead sources',
        description:
          'Create and manage lead sources so you can track leads, bookings and profit that come from various marketing campaigns, website lead forms, landing pages and social media etc.'
      },
      {
        name: 'Lead rotation',
        description:
          'Create a lead rotation, and generate an HTML form to embed on your website so that leads go directly into your pipeline.'
      },
      {
        name: 'Client import',
        description: 'Upload or import your client or lead list from excel with this tool.'
      },
      {
        name: 'Client portal',
        description: 'A client can access all their active projects through the client portal.'
      },
      {
        name: 'Automated texting',
        description: 'Send messages to your clients.'
      }
    ]
  },
  {
    name: 'Estimate',
    items: [
      {
        name: 'Change orders',
        description:
          'Grow your business with better lead management. Easily manage follow-up and share files. Convert leads into projects with a click.'
      },
      {
        name: 'Custom dimensions',
        description:
          'Create and manage lead sources so you can track leads, bookings and profit that come from various marketing campaigns, website lead forms, landing pages and social media etc.'
      },
      {
        name: 'Labor rates lookup',
        description:
          'Create a lead rotation, and generate an HTML form to embed on your website so that leads go directly into your pipeline.'
      },
      {
        name: 'Tax rate management',
        description: 'Upload or import your client or lead list from excel with this tool.'
      },
      {
        name: 'Price/Dimension Linking',
        description: 'A client can access all their active projects through the client portal.'
      },
      {
        name: 'Material price lookup',
        description: 'Send messages to your clients.'
      },
      {
        name: 'Bolster free catalog',
        description:
          'Search our marketplace of beautifully designed and accurately built assemblies and items. Instantly add the items or assemblies to your quote.'
      }
    ]
  },
  {
    name: 'Integrations',
    items: [
      {
        name: 'Quickbooks',
        description:
          'Our Integration with Quickbooks makes it easy for you to keep track of your invoices, taxes and paying your employees.'
      },
      {
        name: 'Webhooks',
        description:
          'Build an event-based connection from Bolster to a webhook aggregator like Zapier or Integromat, or directly to any web-based endpoint for leads, clients, quotes, change-orders and invoices.'
      }
    ]
  },
  {
    name: 'Support',
    items: [
      {
        name: 'Bolster University',
        description:
          'Browse our online learning management system to watch tutorials. Bolster University is continually updated.'
      },
      {
        name: 'Customer Success Coaches',
        description:
          'We include hours of one on one customer success coaching with our packages. Let us help you get setup and building your business.'
      }
    ]
  }
]

export default {
  features
}
