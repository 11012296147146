<template>
  <Card
    class="mx-2 subscription-card"
    :class="{
      recommended: isRecommended(subscription)
    }"
    :flat="true"
  >
    <div class="subscription-header">
      <suggestion-button
        class="subscription-recommended sm info"
        v-if="isRecommended(subscription)"
      >
        Recommended
      </suggestion-button>
      <span>
        <img
          v-if="subscription.metadata.icon === 'ribbon'"
          src="../../../assets/subscriptions/ribbon.svg"
        />
        <img
          v-if="subscription.metadata.icon === 'trophy'"
          src="../../../assets/subscriptions/trophy.svg"
        />
        <img
          v-if="subscription.metadata.icon === 'medal'"
          src="../../../assets/subscriptions/medal.svg"
        />
        <img
          v-if="subscription.metadata.icon === 'crown'"
          src="../../../assets/subscriptions/crown.svg"
        />

        <span class="subscription-title">{{ subscription.name }}</span>
      </span>
      <span class="subscription-description">{{ subscription.metadata.tagline }}</span>
    </div>
    <span class="subscription-price">
      ${{ $f.number(subscription.default_price.unit_amount / 100) }}
      <span class="subscription-price--tagline">/ billed annually</span>
    </span>
    <btn
      @click="() => onSelect(subscription, actionText)"
      :disabled="subscription.current"
      class="info w-100"
      >{{ actionText }}</btn
    >
    <ul class="subscription-limits">
      <li>
        <img src="../../../assets/subscriptions/check.svg" />
        <span v-if="limits && limits.users">{{ limits.users }}</span> users
      </li>
      <li>
        <img src="../../../assets/subscriptions/check.svg" />
        <span v-if="limits && limits.projects">{{ limits.projects }}</span> booked projects
      </li>
      <li>
        <img src="../../../assets/subscriptions/check.svg" />
        {{ subscription.metadata.onboarding }} hours of support
      </li>
      <li>
        <img src="../../../assets/subscriptions/check.svg" />
        {{
          subscription.metadata && subscription.metadata.rate ? subscription.metadata.rate : '0.07'
        }}% transaction fee
      </li>
    </ul>

    <div class="subscription-footer text-center w-100" v-if="limits && limits.projects">
      ${{ $f.number(subscription.default_price.unit_amount / 100 / (limits.projects || 0)) }} per
      project
    </div>
  </Card>
</template>

<script>
import BillingMixin from '../BillingMixin'

export default {
  name: 'Subscription',
  data() {
    return {
      limits: {}
    }
  },
  mixins: [BillingMixin],
  computed: {
    actionText() {
      if (this.subscription.current) return 'Current'
      if (
        this.current &&
        this.subscription.default_price.unit_amount < this.current.default_price.unit_amount
      )
        return 'Downgrade'
      return this.selectText || 'Upgrade'
    }
  },
  props: {
    subscription: {
      type: Object
    },
    onSelect: {
      type: Function
    },
    current: {
      type: Object
    },
    selectText: {
      type: String,
      default: 'Upgrade'
    }
  },
  mounted() {
    const { limits } = this.getSubscriptionLimits(this.subscription)
    this.limits = limits
  },
  methods: {
    isRecommended(subscription) {
      return subscription.recommended || (!this.current && subscription.name === 'Professional')
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
// Lots of custom scss for lots of custom styling
.subscription-limits {
  & > li {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    img {
      margin-right: 10px;
    }
  }
}
.subscription-additional {
  padding-bottom: 40px;
}
.subscription-header {
  display: flex;
  flex-direction: column;
  img {
    margin-top: -10px;
    margin-right: 4px;
  }
}
.subscription-title {
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}
.subscription-price {
  font-weight: 400;
  font-size: 40px;
  line-height: 49px;
  white-space: nowrap;
}
.subscription-price--tagline {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  margin-left: -8px;
}
.subscription-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}
.subscription-footer {
  background-color: $cool-gray-600;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0.8em 0.8em;
  color: $cool-gray-600;
}
.subscription-card {
  width: 250px;
  min-width: 250px;
  padding-top: 12px;
  &.recommended {
    border: solid 2px $blue-print-700 !important;
  }
  .card-body {
    position: relative;
  }
}
.subscription-recommended {
  max-width: 100px;
  position: absolute;
  right: 6px;
  top: 6px;
}
</style>
