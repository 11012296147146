<template>
  <div>
    <Subscriptions
      :plans="subscriptions"
      :onSelectPackage="onSelect"
      :skipConfirm="true"
      :displayEnterprise="true"
      selectText="Select"
      v-if="!subscription"
      :loading="loading"
    />

    <div class="checkout flex flex-column flex-md-row" v-if="!created && subscription">
      <div class="flex flex-column bg-white p-5 package-details">
        <h3 class="mb-2">{{ subscription.name }}</h3>

        <div class="subscription-price mb-3 flex items-center">
          <h3 class="mb-0 ml-0 mr-1 mr-lg-2">
            {{ subscription.default_price.currency.toUpperCase() }}
          </h3>
          <h1 class="subscription-price m-0">
            {{ $f.currency(subscription.default_price.unit_amount / 100) }}
          </h1>
          <span class="subscription-price--tagline ml-1 mt-2">/ annually</span>
        </div>

        <p class="mb-3">{{ subscription.description }}</p>

        <div class="flex border-bottom border-gray justify-between my-2 py-3">
          <p>Subtotal</p>
          <p>{{ $f.currency(subscription.default_price.unit_amount / 100) }}</p>
        </div>

        <a
          v-if="!checkoutCompleted"
          @click="() => (viewPromoCode = !viewPromoCode)"
          class="text-info py-2"
          >{{ viewPromoCode ? 'Hide' : 'Add' }} promotional code</a
        >

        <div v-if="viewPromoCode && !checkoutCompleted" class="position-relative mb-2">
          <field
            placeholder="Enter promotional code"
            class="w-100"
            @input="
              () => {
                foundPromoCode = null
                invalidPromo = false
              }
            "
            v-model="promoCode"
          >
          </field>
          <span
            v-if="promoCode && promoCode !== ''"
            class="apply-code position-absolute flex items-center"
          >
            <a v-if="!validating" class="text-info" @click="applyPromoCode">Apply</a>
            <spinner v-else :loading="1" size="1em" />
          </span>
          <small
            class="text-danger"
            v-if="(foundPromoCode && !foundPromoCode.coupon.valid) || invalidPromo"
          >
            This code is invalid.
          </small>
        </div>

        <div
          v-if="foundPromoCode && foundPromoCode.coupon.valid"
          class="flex justify-between mb-2 pb-3 mt-1"
        >
          <span>
            <p>Discount Code</p>
            <small class="text-muted"
              >{{ foundPromoCode.code }} applied for {{ foundPromoCode.coupon.percent_off }}%
              off</small
            >
          </span>
          <p>-{{ $f.currency(discount / 100) }}</p>
        </div>

        <div
          :class="!checkoutCompleted ? 'border-top border-gray' : ''"
          class="flex justify-between my-2 py-3"
        >
          <p>Total due today</p>
          <p>
            <b>{{ $f.currency(totalPrice / 100) }}</b>
          </p>
        </div>

        <a v-if="checkoutCompleted" class="text-info mt-4" @click="clearCheckout">Clear checkout</a>
      </div>

      <div class="bg-white py-5 px-2 px-md-0 pr-md-5 customer-form">
        <div class="checkout-form">
          <div v-if="!checkoutCompleted" class="contact-information flex flex-column pb-4">
            <field-section>
              <span> Name </span>
              <field v-model="contactInfo.name" :validate="validationObj" class="mb-2"></field>
            </field-section>

            <field-section>
              <span> Company Name </span>
              <field v-model="contactInfo.description" class="mb-2"></field>
            </field-section>

            <field-section>
              <span> Email </span>
              <field
                v-model="contactInfo.email"
                class="mb-2"
                :validate="{
                  type: 'email'
                }"
              ></field>
            </field-section>

            <field-section>
              <PhoneField
                class="phoneField"
                :validate="{ required: true }"
                @validation="(o) => (phoneVerified = o.valid && !o.empty && o.value !== '')"
                v-model="contactInfo.phone"
                :current="contactInfo.phone"
              />
            </field-section>

            <AddressForm
              :errors="errors"
              :required="true"
              namespace="billingDetails"
              v-model="contactInfo.address"
            />
          </div>

          <div v-if="checkoutCompleted">
            <div class="p-4" id="credit-card-payment-element"></div>
          </div>

          <div
            v-if="signingUp || checkingOut || loading"
            class="loading-screen flex items-center justify-center"
          >
            <h4 v-if="loading">
              Loading session...
              <spinner :loading="1" size="1em" class="pl-2 info" />
            </h4>
          </div>
        </div>

        <btn
          v-if="!checkoutCompleted"
          :disabled="!valid"
          @click="onSubmit"
          :loading="checkingOut"
          class="info w-100"
          >{{ checkingOut ? 'Setting up account...' : 'Continue' }}
          <spinner v-if="checkingOut" :loading="1" size="1em" class="pl-2 light" />
        </btn>

        <btn
          v-if="checkoutCompleted"
          :disabled="!canSubmit"
          @click="onSignup"
          :loading="signingUp"
          class="info w-100"
          >{{ signingUp ? 'Confirming subscription...' : 'Subscribe' }}
          <spinner v-if="signingUp" :loading="1" size="1em" class="pl-2 light" />
        </btn>
      </div>
    </div>

    <card class="subscription-change--card" :flat="true" v-if="created">
      <h2 class="text-center mx-auto mb-4 mt-2">
        Congratulations for signing up for the <b>{{ subscription.name }}</b> package!
      </h2>
      <p><b>You have unlocked:</b></p>
      <ul class="subscription-limits">
        <li>
          <img src="../../../assets/subscriptions/check.svg" />
          {{ subscription.metadata.users }} users
        </li>
        <li>
          <img src="../../../assets/subscriptions/check.svg" />
          {{ subscription.metadata.projects }} booked projects
        </li>
        <li>
          <img src="../../../assets/subscriptions/check.svg" />
          {{ subscription.metadata.onboarding }} hours of customer support
        </li>
      </ul>
    </card>

    <SubscriptionSplash v-if="subscription && showSplash" :subscription="subscription" />
  </div>
</template>

<script>
import BillingMixin from '../BillingMixin'
import StripePaymentsMixin from '../../payments/stripe/StripePaymentsMixin'
import Subscriptions from '../subscriptions/Subscriptions.vue'
import PhoneField from '../../ui/fields/PhoneField.vue'
import AddressForm from '../../ui/forms/AddressForm.vue'
import FieldSection from '../../ui/fields/FieldSection.vue'
import SubscriptionSplash from '../../billing/subscriptions/SubscriptionSplash.vue'

export default {
  name: 'SignUpForPackage',
  props: {
    showSplash: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      checkingOut: false,
      signingUp: false,
      subscriptions: null,
      subscription: null,
      paymentMethod: null,
      created: false,
      viewPromoCode: false,
      promoCode: null,
      foundPromoCode: null,
      invalidPromo: false,
      validating: false,
      phoneVerified: false,
      canSubmit: false,
      paymentSetup: null,
      checkoutCompleted: false,
      checkoutSession: null,
      errors: [],
      types: ['regular', 'volume'],
      currencies: ['usd', 'cad'],
      currency: 'usd',
      disableCurrencySelector: false,
      contactInfo: {
        phone: null,
        email: null,
        name: null,
        description: null,
        address: {
          line1: null,
          city: null,
          country: null,
          postal_code: null,
          state: null
        }
      },
      validationObj: {
        format: /^[A-Z][a-z]+\s[A-Z][a-z]+$/,
        required: true,
        message: 'You must provide a first and last name capitalized'
      }
    }
  },
  computed: {
    /**
     * Total price after promo code deduction
     */
    totalPrice() {
      if (!this.foundPromoCode) return this.subscription.default_price.unit_amount
      const percentToPay = 100 - this.foundPromoCode.coupon.percent_off
      return (percentToPay / 100) * this.subscription.default_price.unit_amount
    },
    /**
     * Total deduction from amount owed
     */
    discount() {
      if (!this.foundPromoCode) return 0
      return (
        (this.foundPromoCode.coupon.percent_off / 100) * this.subscription.default_price.unit_amount
      )
    },
    /**
     * Check if the form is valid
     */
    valid() {
      let valid = true
      Object.keys(this.contactInfo).forEach((i) => {
        if (this.contactInfo[i] === null || this.contactInfo[i] === '') {
          valid = false
        }
      })
      return valid
    }
  },
  async mounted() {
    this.fillOutForm()
    this.init()
  },
  methods: {
    fillOutForm() {
      if (
        !this.$store.state.session.user ||
        Object.keys(this.$store.state.session.user).length === 0 ||
        !this.$store.state.session.company ||
        Object.keys(this.$store.state.session.company).length === 0
      ) {
        return
      }

      const {
        user_phone: phone,
        user_email: email,
        user_fname: firstName,
        user_lname: lastName,
        user_address: address,
        user_city: city,
        province_abbr: state,
        user_country: country,
        user_postal: postal
      } = this.$store.state.session.user

      const { company_name: companyName, company_country: companyCountry } =
        this.$store.state.session.company

      this.contactInfo = {
        phone,
        email,
        name: `${firstName} ${lastName}`,
        description: companyName,
        address: {
          line1: address,
          city,
          country,
          postal_code: postal,
          state
        }
      }
      this.currency = companyCountry === 'ca' ? 'cad' : 'usd'
      this.disableCurrencySelector = true
    },
    /**
     * Init and setup form
     */
    async init() {
      this.loading = true
      // check to see if a session has been saved
      const sessionCheckout = await localStorage.getItem('sessionCheckout')
      if (sessionCheckout && sessionCheckout !== 'null') {
        // load session which includes customer and subscription
        await this.loadSession(sessionCheckout)
      }
      if (this.$route.params.slug) {
        // make sure the set type is allowed
        const type = this.types.includes(this.$route.params.type)
          ? this.$route.params.type
          : this.types[0]
        // check the set currency
        const currency = this.currencies.includes(this.$route.params.currency)
          ? this.$route.params.currency
          : this.currencies[0]
        this.currency = currency
        // retrieve the subscription
        const selected = await this.getSubscription(this.$route.params.slug, type, currency)
        // set the retrieved subscription as selected
        this.subscription = selected
      } else if (!this.subscription) {
        // if we want all the subscriptions, currently not in use
        this.subscriptions = await this.getSuggestedSubscriptions()
      }
      this.loading = false
    },
    /**
     * Switch what currency to use
     */
    async toggleCurrency(val) {
      const { slug, type } = this.$route.params
      this.currency = val
      this.foundPromoCode = null
      this.promoCode = null
      // change the param in the url
      await this.$router.push(
        `/pub/pricing/${val.toLowerCase()}/${slug || ''}${type ? `/${type}` : ''}`
      )
      this.init()
    },
    /**
     * parse, retrieve and restore the session
     */
    async loadSession(sessionCheckout) {
      // parse the stored session
      const session = JSON.parse(sessionCheckout)
      // retrieve the subscription
      const type = this.types.includes(this.$route.params.type)
        ? this.$route.params.type
        : this.types[0]
      // check the set currency
      const currency = this.currencies.includes(this.$route.params.currency)
        ? this.$route.params.currency
        : this.currencies[0]
      this.currency = currency
      this.subscription = await this.getSubscription(session.plan.nickname, type, currency)
      // use the stored session info to restore the session
      this.checkoutSession = await this.reloadSession({
        products: {
          flat: [this.subscription.default_price.id]
        },
        coupon: this.foundPromoCode ? this.foundPromoCode.coupon.id : null,
        currency: session.setCurrency,
        customer: session.customer
      })
      this.currency = session.setCurrency
      // setup the session for the form
      this.setupSession()
    },
    /**
     * User wants to clear the session and start new
     */
    clearCheckout() {
      this.checkoutCompleted = false
      this.checkoutSession = null
      localStorage.setItem('sessionCheckout', null)
    },
    /**
     * set all the required data from the session
     */
    setupSession() {
      this.clientSecret = this.checkoutSession.latest_invoice.payment_intent.client_secret
      this.checkoutCompleted = true
      this.checkingOut = false
      this.setupElements()
    },
    /**
     * Handle the signup which creates the user, company and authorizes
     */
    async onSignup() {
      try {
        if (this.signingUp) return
        this.signingUp = true
        await this.confirmPaymentElements()
      } catch (e) {
        this.setupElements()
        this.signingUp = false
        console.log(e, 'could not confirm')
      }
    },
    /**
     * Stripe elements has properly been filled out
     */
    onElementsCompleted() {
      this.canSubmit = true
    },
    /**
     * Load stripe and initiate stripe elements
     */
    async setupElements() {
      this.loading = true
      try {
        this.mapBillingDetails()
        // set billing details so stripe does not ask for them
        // we already ask and fill out these details
        this.billingDetails.address = {
          postal_code: 'MT5 123',
          country: 'US'
        }
        await this.createElements(true)
        await this.createPaymentElement()
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not setup payment elements',
          error: true
        })
      }
      this.loading = false
    },
    /**
     * Elements has been verified and a confirmed payment intent with attached payment
     * method has been returned
     */
    async paymentIntentCreated(verifiedIntent) {
      try {
        const params = this.$route.query
        // create company, user, authorize and create/assign subscription
        const { object, token } = await this.signup({
          customer: this.checkoutSession.customer,
          paymentMethod: verifiedIntent.payment_method,
          subscription: this.checkoutSession.id,
          currency: this.currency,
          price: this.checkoutSession.plan.id,
          params
        })
        // set the returned token
        await this.$store.dispatch('setToken', { token })
        const { company, user } = object
        // set the scope
        await this.$store.dispatch('setScope', {
          scope: {
            user,
            company
          }
        })
        const companies = this.$store.state.session.scopableObjects.company
        // go to the pipeline
        this.$router.push(`/${companies[0].route}/pipeline`).catch(() => {})
        localStorage.setItem('sessionCheckout', null)
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not finish signup.',
          error: true
        })
      }
      this.signingUp = false
    },
    /**
     * Submit the checkout form and initiate checkout
     */
    async onSubmit() {
      if (this.checkingOut) return
      try {
        this.checkingOut = true
        // we want to create a customer, add customer to payment intent
        // and we want to update the amount of payment intent in case promo code used
        this.checkoutSession = await this.checkout({
          products: {
            flat: [this.subscription.default_price.id]
          },
          coupon: this.foundPromoCode ? this.foundPromoCode.coupon.id : null,
          currency: this.currency,
          customer: {
            ...this.contactInfo
          }
        })
        // store the session in local storage
        localStorage.setItem(
          'sessionCheckout',
          JSON.stringify({
            ...this.checkoutSession,
            setCurrency: this.currency
          })
        )
        this.setupSession()
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not create subscription.',
          error: true
        })
        this.checkingOut = false
      }
    },
    /**
     * Handle selecting a subscription
     */
    onSelect(subscription) {
      this.subscription = subscription
    },
    /**
     * Apply the set promo code
     */
    async applyPromoCode() {
      this.validating = true
      this.invalidPromo = false
      try {
        // through api validate
        const res = await this.validatePromoCode(this.promoCode, this.currency)
        this.foundPromoCode = res && (res.length > 0 || Object.keys(res).length > 0) ? res : null
        if (res.length === 0) this.invalidPromo = true
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not validate promo code.',
          error: true
        })
      }
      this.validating = false
    }
  },
  components: {
    Subscriptions,
    PhoneField,
    AddressForm,
    FieldSection,
    SubscriptionSplash
  },
  mixins: [BillingMixin, StripePaymentsMixin]
}
</script>
<style lang="scss" scoped>
//
.cc-container {
  max-width: auto !important;
}
.checkout {
  max-width: 1200px;
  margin: auto;
  > div {
    flex: 1;
  }
}
.apply-code {
  top: 0;
  bottom: 0;
  right: 10px;
  height: 3em;
}
.field-component {
  font-weight: 400 !important;
}

.checkout-form {
  position: relative;
}

.loading-screen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  right: 0;
  //background-color: $flame-white;
  opacity: 0.5;
  z-index: 99;
  height: 100%;
  width: 100%;
}

@media (min-width: 992px) {
  .package-details {
    border-top-left-radius: 1em;
    border-bottom-left-radius: 1em;
  }

  .customer-form {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
  }
}
</style>
