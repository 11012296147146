<template>
  <div
    :style="`background: url(${bg}) no-repeat center center / cover;`"
    class="subscription-splash"
    v-if="!hide"
  >
    <div class="container h-lg-100 mt-lg-5 pb-5">
      <div class="row pt-5">
        <div class="col-12">
          <img class="logo-img" src="../../../assets/SVG/cc-logo-white.svg" />
        </div>
      </div>
      <div class="row h-lg-100 pt-5">
        <div class="col-12 col-lg-7 flex flex-column h-lg-100 pr-lg-5">
          <h1 class="text-white mb-3 font-weight-bold">
            Bring your estimates to life.<br />
            Get started today!
          </h1>
          <h3 class="text-white font-weight-normal">
            Our dedicated Customer Success team is ready to support you.
          </h3>
          <ul class="mt-4">
            <li class="my-4 flex">
              <img :src="handshake" />
              <p class="lead text-white pt-2">One-on-one Zoom sessions</p>
            </li>
            <li class="my-4 flex">
              <img :src="money" class="money" />
              <p class="lead text-white">
                Access to cost database of over 17k items priced by ZIP/Postal Code including unit
                costs
              </p>
            </li>
            <li class="my-4 flex">
              <img :src="contractor" />
              <p class="lead text-white">
                Customize your assemblies, upgrades, selection items and optional items
              </p>
            </li>
            <li class="my-4 flex">
              <img :src="proposals" />
              <p class="lead text-white">
                Create attractive and customized proposals so you can win more jobs and focus on
                growing your business!
              </p>
            </li>
            <li class="my-4 flex">
              <img :src="university" class="university" />
              <p class="lead text-white">Access to Bolster University</p>
            </li>
          </ul>
        </div>
        <div
          class="col-12 col-lg-4 flex justify-center flex-column h-lg-100 mt-5 mt-md-0 pb-5 pb-lg-0"
        >
          <Card class="px-2 px-xl-3 py-4 subscription-card mb-5" :flat="true">
            <div class="subscription-header">
              <div class="flex">
                <img
                  v-if="subscription.metadata.icon === 'ribbon'"
                  src="../../../assets/subscriptions/ribbon.svg"
                />
                <img
                  v-if="subscription.metadata.icon === 'trophy'"
                  src="../../../assets/subscriptions/trophy.svg"
                />
                <img
                  v-if="subscription.metadata.icon === 'medal'"
                  src="../../../assets/subscriptions/medal.svg"
                />
                <img
                  v-if="subscription.metadata.icon === 'crown'"
                  src="../../../assets/subscriptions/crown.svg"
                />

                <h2 class="subscription-title">{{ subscription.name }}</h2>
              </div>
              <span class="subscription-description">{{ subscription.metadata.tagline }}</span>
            </div>
            <span class="flex items-center w-100">
              <h2 class="mr-2">{{ subscription.default_price.currency.toUpperCase() }}</h2>
              <h1>${{ $f.number(subscription.default_price.unit_amount / 100) }}</h1>
              <span class="subscription-price--tagline">/ annually</span>
            </span>
            <ul class="subscription-limits">
              <li class="flex my-3" v-if="limits && limits.users">
                <img src="../../../assets/subscriptions/check.svg" />
                <p>
                  <span>{{ limits.users }}</span> {{ $f.pluralize('user', +limits.users) }}
                </p>
              </li>
              <li class="flex my-3">
                <img src="../../../assets/subscriptions/check.svg" />
                <p>Unlimited estimates</p>
              </li>
              <li class="flex my-3" v-if="limits && limits.projects">
                <img src="../../../assets/subscriptions/check.svg" />
                <p>
                  <span>{{ limits.projects }}</span> booked
                  {{ $f.pluralize('projects', +limits.projects) }} per year
                </p>
              </li>
              <li class="flex my-3">
                <img src="../../../assets/subscriptions/check.svg" />
                <p>
                  {{ subscription.metadata.onboarding }}
                  {{ $f.pluralize('hour', +subscription.metadata.onboarding) }} of onboarding
                  support
                </p>
              </li>
              <li class="flex my-3">
                <img src="../../../assets/subscriptions/check.svg" />
                <p>Integrated invoice payments</p>
              </li>
            </ul>

            <btn @click="onClose" :disabled="subscription.current" class="info w-100">
              Buy Now
            </btn>
          </Card>
        </div>
      </div>
    </div>
    <div class="container mt-5 pb-5 pb-xl-0 mb-5 mb-xl-0">
      <div class="row mb-4">
        <div class="col-12 flex justify-center justify-content-lg-end w-100 mr-4">
          <a class="text-white" href="/pub/legal">Privacy Policy</a>
          <span class="text-white mx-2"> | </span>
          <a class="text-white" href="/pub/legal">Terms & Conditions</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p class="text-center text-white">
            Copyright © 2024<br />
            Bolster. All right reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import BillingMixin from '../BillingMixin'

import bg from '../../../assets/pricing-bg.png'
import contractor from '../../../assets/SVG/contractor.svg'
import proposals from '../../../assets/SVG/proposals.svg'
import handshake from '../../../assets/SVG/handshake.svg'
import money from '../../../assets/SVG/money.svg'
import logo from '../../../assets/SVG/cc-logo-white.svg'
import university from '../../../assets/SVG/university.svg'

export default {
  name: 'SubscriptionSplash',
  data() {
    return {
      bg,
      contractor,
      proposals,
      handshake,
      money,
      logo,
      university,
      limits: {},
      hide: false
    }
  },
  methods: {
    onClose() {
      this.hide = true
    }
  },
  components: {},
  mixins: [BillingMixin],
  props: {
    subscription: Object
  },
  mounted() {
    const { limits } = this.getSubscriptionLimits(this.subscription)
    this.limits = limits
  }
}
</script>

<style lang="scss" scoped>
.subscription-splash {
  min-height: 100vh;
  width: 100vw;
  background-color: white;
  position: absolute;
  overflow-y: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  img {
    width: 3.5rem;
    margin-right: 1rem;
    &.money {
      width: 1.8rem;
      margin-right: 1.9rem;
      margin-left: 0.6rem;
    }
    &.university {
      margin-right: 1.6rem;
      width: 2.5rem;
    }
  }
  .subscription-limits {
    img {
      width: 1rem;
    }
  }
  .subscription-header {
    img {
      width: 2rem;
      margin-top: -12px;
    }
  }

  .logo-img {
    width: 18rem;
  }

  h1 {
    font-size: 3rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .container {
    max-width: 1400px !important;
  }

  .lead {
    font-size: 1.4rem;
  }

  a {
    &:hover {
      // color: $primary!important;
    }
  }

  @media (max-width: 992px) {
    h1 {
      font-size: 3rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    .container {
      max-width: 1400px !important;
    }

    .lead {
      font-size: 1.3rem;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 2.6rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    .container {
      max-width: 1400px !important;
    }

    .lead {
      font-size: 1.3rem;
    }
  }
}
</style>
