<template>
  <div>
    <div
      v-if="!newSubscription && !selectedSubscription"
      class="mt-4 pt-4 px-4 subscriptions-wrapper justify-center-start justify-content-xl-center"
    >
      <div class="subscriptions-ctn flex" v-if="!creating && !newSubscription">
        <SubscriptionCard
          v-for="sub in subscriptions"
          :key="sub.id"
          :current="currentSubscription"
          :onSelect="onSelect"
          :selectText="selectText"
          :subscription="sub"
        />
      </div>
      <div class="subscriptions-ctn flex mx-2" v-if="!creating && !loading && displayEnterprise">
        <Card class="enterprise">
          <div class="flex flex-column w-100">
            <span class="enterprise-title">
              <img src="../../../assets/subscriptions/enterprise.svg" />
              Enterprise
            </span>
            <span class="enterprise-description">Scale with confidence</span>
            <span class="enterprise-price"> Custom </span>
            <btn @click="gotoDemoSignUp" class="info w-100">Book a demo</btn>
            <ul class="enterprise-limits">
              <li><img src="../../../assets/subscriptions/check.svg" /> 15+ users</li>
              <li><img src="../../../assets/subscriptions/check.svg" /> 350+ booked projects</li>
              <li><img src="../../../assets/subscriptions/check.svg" /> 40+ hours of support</li>
            </ul>
          </div>
        </Card>
      </div>

      <div v-if="creating || loading" class="w-100 flex justify-center">
        <spinner class="light transaction-list-loader" :loading="1" size="2em" />
        <span class="ml-2 mt-2 text-white">
          {{ loading ? 'Loading subscriptions' : `${lookup[action]} subscription...` }}
        </span>
      </div>
    </div>

    <card
      class="subscription-change--card"
      :flat="true"
      v-if="newSubscription && selectedSubscription && action === 'Upgrade'"
    >
      <h2 class="text-center mx-auto mb-4 mt-2">
        Congratulations for upgrading to the <b>{{ selectedSubscription.name }}</b> package!
      </h2>
      <p><b>You have unlocked:</b></p>
      <ul class="subscription-limits">
        <li><img src="../../../assets/subscriptions/check.svg" /> {{ limits.users }} users</li>
        <li>
          <img src="../../../assets/subscriptions/check.svg" /> {{ limits.projects }} booked
          projects
        </li>
        <li>
          <img src="../../../assets/subscriptions/check.svg" />
          {{ selectedSubscription.metadata.onboarding }} hours of customer support
        </li>
      </ul>
    </card>

    <card
      class="subscription-change--card"
      :flat="true"
      v-if="newSubscription && selectedSubscription && action === 'Downgrade'"
    >
      <h2 class="text-center mx-auto mb-4 mt-2">
        You have been downgraded to the <b>{{ selectedSubscription.name }}</b> package. You can
        always upgrade if you hit any limitations.
      </h2>
      <p><b>You have been reduced to:</b></p>
      <ul class="subscription-limits">
        <li><img src="../../../assets/subscriptions/check.svg" /> {{ limits.users }} users</li>
        <li>
          <img src="../../../assets/subscriptions/check.svg" /> {{ limits.projects }} booked
          projects
        </li>
        <li>
          <img src="../../../assets/subscriptions/check.svg" />
          {{ selectedSubscription.metadata.onboarding }} hours of customer support
        </li>
      </ul>
    </card>

    <FeatureList />
  </div>
</template>

<script>
import BillingMixin from '../BillingMixin'
import SubscriptionCard from '../subscriptions/SubscriptionCard.vue'
import FeatureList from './FeatureList.vue'

export default {
  name: 'Subscriptions',
  props: {
    plans: {
      type: Array
    },
    loading: {
      type: Boolean,
      default: false
    },
    clearOnUpgrade: {
      type: Boolean,
      default: false
    },
    onSelectPackage: {
      type: Function
    },
    selectText: {
      type: String,
      default: 'Upgrade'
    },
    skipConfirm: {
      type: Boolean,
      default: false
    },
    displayEnterprise: {
      type: Boolean,
      default: false
    }
  },
  emits: ['updated'],
  data() {
    return {
      subscriptions: [],
      selectedSubscription: null,
      currentSubscription: null,
      creating: false,
      newSubscription: null,
      limits: null,
      costs: null,
      lookup: {
        Downgrade: 'Downgrading',
        Upgrade: 'Upgrading'
      }
    }
  },
  watch: {
    /**
     * After the subscriptions are done loading sort them and
     * set selected subscription
     */
    loading() {
      if (this.loading) return
      this.selectedSubscription = null
      this.newSubscription = null
      this.creating = false
      if (this.plans.length > 1) {
        // make sure plans are sorted by price
        this.subscriptions = this.plans
          .sort((a, b) => b.default_price.unit_amount - a.default_price.unit_amount)
          .reverse()
      } else {
        this.subscriptions = this.plans
      }
      // the current subscription is set on the back-end
      this.currentSubscription = this.subscriptions.find((i) => i.current)
    }
  },
  methods: {
    /**
     * Schedule a demo
     */
    gotoDemoSignUp() {
      window.open(
        'https://costcertified.com/book-a-demo/?utm_source=Direct&utm_medium=Website',
        '_blank'
      )
    },
    /**
     * handle selecting a package
     */
    async handleSelect(subscription, action) {
      this.creating = true
      this.action = action
      try {
        const res = await this.changeSubscription({
          products: {
            flat: [subscription.default_price.id]
          }
        })
        // When hitting the paywall we want to either continue normally
        // or we want to re-show the paywall if limit is still hit
        if (!this.clearOnUpgrade) {
          this.selectedSubscription = subscription
          this.newSubscription = res
        }
        this.$emit('updated', {
          action,
          subscription: res
        })
        // get the new limits
        const { limits } = this.getSubscriptionLimits(this.selectedSubscription)
        this.limits = limits
      } catch (e) {
        this.$store.dispatch('alert', {
          message: e.message || 'Could not upgrade subscription.',
          error: true
        })
      }
      this.creating = false
    },
    /**
     * Selecting a subscription either an upgrade or downgrade
     */
    onSelect(subscription, action) {
      const actionMethod = this.onSelectPackage ? this.onSelectPackage : this.handleSelect
      if (this.skipConfirm) {
        actionMethod(subscription, action)
        return
      }
      // make sure the user confirms
      const actionText = action.toLowerCase()
      let msg = `Are you sure you want to ${action.toLowerCase()}?`
      if (actionText === 'upgrade') {
        msg = `Take your business to the next level and upgrade to the <b>${subscription.name}</b> package?`
      }
      this.$store.dispatch('modal/confirm', {
        message: msg,
        actions: {
          confirm: {
            title: `${action} package`,
            action: () => actionMethod(subscription, action)
          },
          cancel: {
            title: 'Cancel'
          }
        }
      })
    }
  },
  components: {
    SubscriptionCard,
    FeatureList
  },
  mixins: [BillingMixin]
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.subscription-change--card {
  max-width: 850px;
  margin: 0 auto;
}
.subscriptions-wrapper {
  display: inline-block;
  min-height: 450px;
  overflow-x: auto;
  max-width: 100%;
  display: flex;
  padding-bottom: 2em;
}
.enterprise {
  width: 250px;
  min-width: 250px;
  padding-top: 12px;
  .enterprise-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    img {
      margin-top: -5px;
    }
  }
  .enterprise-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
  .enterprise-price {
    font-weight: 400;
    font-size: 40px;
    line-height: 49px;
    white-space: nowrap;
  }
  a,
  .enterprise-limits,
  .enterprise-price {
    margin-top: 1rem;
  }
  .enterprise-limits {
    li {
      font-weight: 400;
      img {
        margin-right: 10px;
      }
    }
  }
}
</style>
