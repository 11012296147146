<template>
  <div id="featureList" class="feature-list mt-4 py-3">
    <h3 class="text-center">All packages include the following features:</h3>
    <div class="mb-4 list-group" v-for="category in features" :key="category.name">
      <h5>{{ category.name }}</h5>
      <CardListCollapse :condensed="true" v-for="feature in category.items" :key="feature.name">
        <template #title>
          <span class="feature-item flex justify-between w-100">
            <span>{{ feature.name }}</span>
            <img src="../../../assets/subscriptions/check.svg" />
          </span>
        </template>
        {{ feature.description }}
      </CardListCollapse>
    </div>
  </div>
</template>

<script>
import { features } from './features'

export default {
  name: 'FeatureList',
  data() {
    return {
      features
    }
  },
  components: {},
  mixins: [],
  watch: {},
  methods: {
    createId(name) {
      return name
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (n, i) => (i === 0 ? n.toLowerCase() : n.toUpperCase()))
        .replace(/\s+/g, '')
    }
  },
  computed: {
    categories() {
      return Object.keys(this.features)
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.feature-list {
  max-width: 850px;
  margin: 0 auto;
}
.feature-item {
  img {
    height: 20px;
  }
}
</style>
